import { AlzaClick } from "helpers/alzaClick";
import { FC } from "react";

interface props {
    id: string
    className?: string
}

const AlzaBanner: FC<props> = ({id, className}) => {
    const data = `https://affil.alza.cz/bnr/${id}`

    return (
        <div className={className} onClick={() => AlzaClick.bannerClicked()}>
            <object data={data} width="100%" height="100%" />
        </div>
    )
}

export default AlzaBanner