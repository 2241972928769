import { FC, Fragment, createElement, useMemo } from 'react'

interface props {
  content: string | string[]
}

const HTML: FC<props> = ({ content }) => {
  const elements = useMemo(() => {
    const div = document.createElement('div')
    div.innerHTML = typeof content === 'string' ? content : content.join('')

    const reactElements = Array.from(div.children).map((el) => {
      const ReactEl = createElement(el.tagName.toLowerCase(), {dangerouslySetInnerHTML: el.innerHTML ? {__html: el.innerHTML} : undefined, className: [el.className, "font-medium"].asClass})
      return ReactEl
    })

    return reactElements
  }, [content])

  return (
    <>
      {elements.map((el, i) => {
        return <Fragment key={i}>{el}</Fragment>
      })}
    </>
  )
}

export default HTML
