import { FC, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

interface Props {
  open: boolean
  close: () => void
}

const Modal: FC<PropsWithChildren<Props>> = ({ children, open, close }) => {
  return (
    <CSSTransition in={open} timeout={200} classNames="modal" unmountOnExit>
      <>
        {ReactDOM.createPortal(
          <div className="flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 backdrop-brightness-50 z-10" onClick={close}>
            <div className="bg-yellow-50 p-4 rounded-lg shadow-lg max-w-[calc(100%-2rem)] max-h-[calc(100%-2rem)] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </div>,
          document.getElementById('modals')!
        )}
      </>
    </CSSTransition>
  )
}

export default Modal
