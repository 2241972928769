import { getTestAsset } from 'helpers'
import { ChangeEvent, FC, MouseEvent, PointerEvent, useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as PauseIcon } from 'assets/iconsax/outline/pause.svg'
import { ReactComponent as PlayIcon } from 'assets/iconsax/outline/play.svg'

interface props {
  recording: string
}

const Audio: FC<props> = ({ recording }) => {
  const ref = useRef<HTMLAudioElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const [playing, setPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const seeking = useRef(false)
  const changingPlayState = useRef(false)

  const play = useCallback(() => {
    if (!ref.current) return
    if (changingPlayState.current) return
    if (!ref.current.paused) return
    changingPlayState.current = true
    ref.current.play().finally(() => {
      seeking.current = false
      changingPlayState.current = false
    })
  }, [])

  const pause = useCallback(() => {
    if (!ref.current) return
    if (changingPlayState.current) return
    ref.current.pause()
  }, [])

  useEffect(() => {
    if (!playing) return
    const cb = () => {
      if (ref.current) {
        const { currentTime, duration } = ref.current
        setProgress(currentTime / duration)
      }
    }
    const interval = setInterval(cb, 100)
    return () => {
      clearInterval(interval)
    }
  }, [playing])

  useEffect(() => {
    const screenEl = document.body
    if (!screenEl) return

    const screenRect = screenEl.getBoundingClientRect()
    const testTop = screenRect.top
    const testBottom = screenRect.bottom

    if (!wrapper.current) return
    const recordingRect = wrapper.current.getBoundingClientRect()
    const recordingTop = recordingRect.top
    const recordingBottom = recordingRect.bottom
    if (recordingTop < testTop) {
      pause()
    } else if (recordingBottom > testBottom) {
      pause()
    }
  }, [progress, pause])

  const toggle = useCallback(
    (e: PointerEvent | MouseEvent) => {
      if (ref.current) {
        if (ref.current.paused) {
          play()
        } else {
          pause()
        }
      }
    },
    [play, pause]
  )

  const onChangeCB = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!ref.current) return
      seeking.current = true
      !ref.current.paused && pause()
      ref.current.currentTime = e.currentTarget.valueAsNumber * ref.current.duration
    },
    [pause]
  )

  const onBlur = useCallback(() => {
    if (!ref.current) return
    if (!seeking.current) return
    play()
  }, [play])

  useEffect(() => {
    window.addEventListener('pointerup', onBlur)
    return () => {
      window.removeEventListener('pointerup', onBlur)
    }
  }, [onBlur])

  const iconClass = ['w-12 h-12 fill-yellow-300 bg-kura z-[100] rounded-l-xl transition-all p-2 hover:p-2.5 transition-all cursor-pointer', !playing && 'mobile:rounded-xl mobileWidth:rounded-xl']
    .asClass

  return (
    <div ref={wrapper} className={['mx-8 mobileWidth:mx-4 mobileWidth:mt-4 h-12 flex transition-all rounded-l-xl overflow-hidden items-center gap-2'].asClass}>
      <div className={['flex z-10 h-12 relative transition-all duration-500 w-12'].asClass}>
        {playing ? <PauseIcon className={iconClass} onClick={toggle} /> : <PlayIcon className={iconClass} onClick={toggle} />}
        <div
          className={
            ['w-2 h-full bg-yellow-100 absolute right-0 translate-x-full mobile:hidden mobileWidth:hidden transition-opacity duration-500', playing ? 'opacity-0 delay-500' : 'opacity-1'].asClass
          }
        />
        <div
          className={
            ['absolute mobile:hidden mobileWidth:hidden overflow-hidden pointer-events-none h-full transition-all aspect-[0.5] z-10 left-full', playing ? 'translate-x-2' : '-translate-x-0'].asClass
          }
        >
          <div className="h-0 w-0 border-kura absolute left-0 top-0 -translate-x-1/2 -translate-y-1/2 rounded-full pointer-events-none" style={{ borderWidth: '1.5rem' }} />
          <div className="h-0 w-0 border-kura absolute left-0 bottom-0 -translate-x-1/2 translate-y-1/2 rounded-full pointer-events-none" style={{ borderWidth: '1.5rem' }} />
        </div>
      </div>
      <div
        className={
          ['flex h-full cursor-pointer grow bg-yellow-200 transition-all duration-500 relative overflow-hidden rounded-r-xl', playing ? 'translate-x-0 opacity-1' : '-translate-x-full opacity-0']
            .asClass
        }
      >
        <input type="range" min={0} max={1} step={0.00001} className="w-full h-full absolute left-0 top-0 opacity-0 cursor-pointer" value={progress} onChange={onChangeCB} />
        <div
          className="bg-yellow-300 h-full w-full transition-transform will-change-transform duration-100 pointer-events-none relative overflow-x-visible"
          style={{ transform: `translateX(-${(1 - progress) * 100}%)`, pointerEvents: 'none' }}
        >
          <div className="h-full aspect-square absolute right-0 translate-x-1/2 rounded-full bg-yellow-300" />
        </div>
      </div>
      <audio ref={ref} onPlay={() => setPlaying(true)} onPause={() => !seeking.current && setPlaying(false)} src={getTestAsset(recording)} className="hidden" />
    </div>
  )
}

export default Audio
