import { FC, useCallback, useState } from 'react'
import Modal from '../Modal'

interface Props {
  open: boolean
  close: () => void
  signInWithEmailLink: (email: string) => Promise<void>
}

const AuthModalContent: FC<Props> = ({ open, close, signInWithEmailLink }) => {
  const [sendingEmail, setSendingEmail] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const modalClose = useCallback(() => {
    if (sendingEmail) {
      return
    }
    setEmailSent(false)
    setSendingEmail(false)
    close()
  }, [sendingEmail, close])

  return (
    <Modal open={open} close={modalClose}>
      <div className="grid gap-y-1 text-center">
        {!sendingEmail && emailSent ? (
          <>
            <header className="text-lg font-bold text-kura">Email odoslaný</header>
            <p className="text-sm text-kura">Odkaz na prihlásenie bol odoslaný na tvoju emailovú adresu.</p>
          </>
        ) : sendingEmail ? (
          <>
            <header className="text-lg font-bold text-kura">Odosielam email</header>
            <p className="text-sm text-kura">Čakaj prosím...</p>
          </>
        ) : (
          <>
            <header className="text-lg font-bold text-kura">Prihlás sa pomocou emailu</header>
            <p className="text-sm text-kura">Na tvoju emailovú adresu ti pošleme odkaz, ktorým sa môžeš prihlásiť.</p>
            <form
              className="grid gap-4 py-3"
              onSubmit={(e) => {
                e.preventDefault()
                const email = e.currentTarget.email.value
                setSendingEmail(true)
                signInWithEmailLink(email)
                  .then(() => {
                    setEmailSent(true)
                  })
                  .finally(() => {
                    setSendingEmail(false)
                  })
                return false
              }}
            >
              <input type="email" name="email" disabled={sendingEmail} placeholder="Email" className="input-primary" />
              <button type="submit" disabled={sendingEmail} className="button-primary">
                Odoslať
              </button>
            </form>
          </>
        )}
      </div>
    </Modal>
  )
}

export default AuthModalContent
