import { User } from "firebase/auth";
import { createContext } from "react";
import { IUser } from "types/users";

export interface AuthContextData {
  user?: User | null
  profile?: IUser | null
  beginSignInWithEmail: () => void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export default AuthContext