import { useEffect, FC, PropsWithChildren, useState, useCallback } from "react"
import StatsContext from "./statsContext"
import { useIndexedDB } from 'modules/db/hooks'
import { ITestCompletion, ITestPeriod } from 'types/testCompletions'
import useAuthContext from "contexts/Auth/useAuthContext"
import { and, collection, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore"
import firebase from "config/firebase"
import { indexedDB } from "modules/db/config"

const openDatabase = (database: string, store: string) => {
  const version = indexedDB.version;
  return new Promise<IDBDatabase>((resolve) => {
    const dbRequest = window.indexedDB.open(database, version)
    dbRequest.onupgradeneeded = (e) => {
      if (store === "testCompletions") {
        // @ts-ignore
        const db = e.target.result;
        const objectStore = db.objectStore('testCompletions', { keyPath: 'id' });
        objectStore.createIndex('user', 'user', { unique: false });  // Create an index on the 'user' field
      }
    }

    dbRequest.onsuccess = () => {
      resolve(dbRequest.result)
    }
  })
}

const testCompletionsRef = collection(firebase.firestore, "testCompletions");
const userQuery = (uid: string, testPeriods: ITestPeriod[], updatedAfter = "") => query(testCompletionsRef, and(where("user", "==", uid), where("completedAt", ">", updatedAfter), where("testPeriod", "in", testPeriods)))

const StatsProvider: FC<PropsWithChildren> = ({ children }) => {
  const {profile} = useAuthContext()
  const rawStatsStore = useIndexedDB<ITestCompletion>("firestore_testCompletions")
  const [rawStats, setRawStats] = useState<ITestCompletion[]>()

  const reloadDatabase = useCallback(async () => {
    if (!profile?.id) {
      setRawStats(undefined)
    } else {
      const db = await openDatabase(indexedDB.name, "testCompletions")
      const tx = db.transaction(["firestore_testCompletions"], "readonly")
      const keyRangeValue = IDBKeyRange.only(profile.id)
      const objectStore = tx.objectStore("firestore_testCompletions")
      const userIndex = objectStore.index("user")
      await new Promise<ITestCompletion[]>((resolve) => {
        const request = userIndex.getAll(keyRangeValue)
        request.onsuccess = () => {
          resolve(request.result)
        }
      }).then(setRawStats)
    }
  }, [profile?.id])

  const handleSnapshot = useCallback(async (snapshot: QuerySnapshot) => {
        await Promise.all(snapshot.docs.map((doc) => rawStatsStore.update(doc.data() as ITestCompletion)))
        if (snapshot.size) await reloadDatabase()
  }, [rawStatsStore, reloadDatabase])
  
  useEffect(() => {
    if (!profile) {
      setRawStats(undefined)
    } else {
      if (!profile.testPeriods.length) {
        setRawStats(old => (old || []))
        return 
      }
      const latestUpdatedAt = rawStats?.reduce((max, curr) => new Date(max).getTime() > new Date(curr.completedAt).getTime() ? max : curr.completedAt, "")
      const unsubscribe = onSnapshot(userQuery(profile.id, profile.testPeriods, latestUpdatedAt), handleSnapshot)
      return () => unsubscribe()
    }
  }, [profile, handleSnapshot, rawStats])

  return (
    <StatsContext.Provider value={{ rawStats }}>
        {children}
    </StatsContext.Provider>
  )
}

export default StatsProvider