import { FC } from "react";
import { Outlet, useLocation } from "react-router";
import Sidebar from "./Sidebar";
import DubkovePozadie from "components/DubkovePozadie";
import useTestContext from "contexts/TestContext/hook";
import useIsMobile from "hooks/useIsMobile";

const MainLayout: FC = () => {
    const {started, data} = useTestContext()
    const path = useLocation().pathname
    const mobile = useIsMobile()

    const hideDubky = path.startsWith("/previous") || (path.startsWith("/shorts") && mobile)

    return (
        <div className="w-screen h-full overflow-visible flex bg-back">
            <Sidebar />
            <div className={["h-full grow z-10 relative", !data && "overflow-y-auto"].asClass}>
                <Outlet />
            </div>
            {!started && !hideDubky && <DubkovePozadie />}
        </div>
    )
} 

export default MainLayout;