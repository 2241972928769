import { FC, useCallback, useEffect, useState } from 'react'
import Modal from '../Modal'

interface Props {
  open: boolean
  close: () => void
  link?: string
}

const PaymentModal: FC<Props> = ({ open, close, link }) => {
  const [sentToMom, setSentToMom] = useState(false)

  const sendToMom = useCallback(() => {
    if (!link) return
    window.navigator.clipboard.writeText(link)
    setSentToMom(true)
  }, [link])

  const payNow = useCallback(() => {
    window.open(link, '_self')
  }, [link])

  useEffect(() => {
    setSentToMom(false)
  }, [link])

  return (
    <Modal open={open} close={close}>
      <div className="grid gap-y-4 text-center">
        {sentToMom ? (
          <p className="text-sm text-kura">Odkaz máš skopírovaný</p>
        ) : (
          <>
            <button type="submit" className="button-primary w-80" onClick={sendToMom}>
              Poslať rodičom
            </button>
            <button type="submit" className="button-primary w-80" onClick={payNow}>
              Zaplatiť teraz
            </button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default PaymentModal
