import { FC } from 'react'
import useShortsContext from 'contexts/ShortsContext/hook'
import { useNavigate } from 'react-router'
import StreakCounter from 'components/StreakCounter'

interface props {
  hide: () => void
}

const ShortsSidebarContent: FC<props> = ({ hide }) => {
  const { subjectName } = useShortsContext()
  const navigate = useNavigate()

  return (
    <div className="w-full flex flex-col grow animate-fade-in">
      <span className="text-lg bg-yellow-200 rounded-xl text-kura p-4 font-semibold w-full text-center leading-[130%] mt-8">{subjectName} - Shorts</span>
      <div className="px-12 grow">
        <StreakCounter />
      </div>
      <div>
        <button
          className="button-primary mt-4"
          onClick={() => {
            navigate('/shorts')
          }}
        >
          Koniec
        </button>
      </div>
    </div>
  )
}

export default ShortsSidebarContent
