import useStatsContext from 'contexts/Stats/useStatsContext'
import { isCorrect } from 'helpers'
import { FC, useDeferredValue, useMemo, useState } from 'react'
import { UserSerie } from 'react-charts'
import { useParams } from 'react-router'
import { ITestCompletion } from 'types/testCompletions'
import { IAnswered } from 'types/tests'
import Graph, { DataPoint } from './components/Graph'

const period = ['Last7', 'Last14', 'Last30', 'All'] as const

type Period = (typeof period)[number]

const getDay = (isoDate: string) => {
  const date = new Date(isoDate)
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
  return localDate
}

type StatsByDay = Record<string, ITestCompletion[]>

const getStatsByDay = (stats: ITestCompletion[]) => {
  return stats.reduce((acc, completion) => {
    const day = getDay(completion.completedAt)
    return {
      ...acc,
      [day]: [...(acc[day] || []), completion],
    }
  }, {} as StatsByDay)
}

const getDaysInPeriod = (period: Period, stats: StatsByDay) => {
  const today = new Date()
  today.setHours(23, 59, 59, 0)
  let until = new Date(today)
  if (period === 'Last7') {
    until.setDate(until.getDate() - 7)
  } else if (period === 'Last14') {
    until.setDate(until.getDate() - 14)
  } else if (period === 'Last30') {
    until.setDate(until.getDate() - 30)
  } else {
    const firstDate = Object.keys(stats).reduce((min, date) => Math.min(min, new Date(date).getTime()), Date.now() - 7 * 24 * 60 * 60 * 1000)
    until = new Date(firstDate)
  }
  const day = new Date(today)
  const out: string[] = []
  while (day >= until) {
    out.push(getDay(day.toISOString()))
    day.setDate(day.getDate() - 1)
  }
  return out.reverse()
}

type DataSeries = Record<string, number>

const accumulateByDate = (dates: string[], stats: DataSeries): DataSeries => {
  let currentValue = 0
  const out = {} as DataSeries
  for (const date of dates) {
    if (stats[date]) {
      out[date] = (out[date] || currentValue) + stats[date]
      currentValue += stats[date]
    } else {
      out[date] = currentValue
    }
  }
  return out
}

const withLastByDate = (dates: string[], stats: DataSeries): DataSeries => {
  let currentValue = 0
  const out = {} as DataSeries
  for (const date of dates) {
    if (stats[date]) {
      out[date] = (out[date] || currentValue) + stats[date]
      currentValue = stats[date]
    } else {
      out[date] = currentValue
    }
  }
  return out
}

const getCorrectAnswersByDate = (stats: StatsByDay): DataSeries => {
  return Object.entries(stats).reduce((series, [date, completions]) => {
    return {
      ...series,
      [date]: completions.reduce((sum, completion) => {
        return sum + completion.answers.reduce((total, answer) => total + (isCorrect(answer as IAnswered) ? 1 : 0), 0)
      }, 0),
    }
  }, {})
}

const getTotalAnsweredByDate = (stats: StatsByDay): DataSeries => {
  return Object.entries(stats).reduce((series, [date, completions]) => {
    return {
      ...series,
      [date]: completions.reduce((sum, completion) => {
        return sum + completion.answers.reduce((total, { answer }) => total + (typeof answer === 'string' ? (answer ? 1 : 0) : answer !== null ? 1 : 0), 0)
      }, 0),
    }
  }, {})
}

const getTotalQuestionsByDate = (stats: StatsByDay): DataSeries => {
  return Object.entries(stats).reduce((series, [date, completions]) => {
    return {
      ...series,
      [date]: completions.reduce((sum, completion) => {
        return sum + completion.answers.length
      }, 0),
    }
  }, {})
}

const getTotalTestsByDate = (stats: StatsByDay): DataSeries => {
  return Object.entries(stats).reduce((series, [date, completions]) => {
    return {
      ...series,
      [date]: completions.length,
    }
  }, {})
}

const toSeries = (dates: string[], dataSeries: DataSeries): UserSerie<DataPoint> => {
  return {
    data: dates.map((date) => ({
      date,
      value: dataSeries[date] || 0,
    })),
  }
}

const getStatsForPeriod = (period: Period, testPeriod: string, stats: ITestCompletion[], type?: string) => {
  const filteredStats = stats.filter((stat) => (!type || stat.type === type) && testPeriod === stat.testPeriod)
  const statsByDay = getStatsByDay(filteredStats)
  const dates = getDaysInPeriod(period, statsByDay)
  const correctByDate = getCorrectAnswersByDate(statsByDay)
  const questionsByDate = getTotalQuestionsByDate(statsByDay)
  const answersByDate = getTotalAnsweredByDate(statsByDay)
  const totalTestsByDate = getTotalTestsByDate(statsByDay)
  const successRate = Object.keys(questionsByDate).reduce(
    (stats, date) => ({
      ...stats,
      [date]: questionsByDate[date] ? answersByDate[date] / questionsByDate[date] : 0,
    }),
    {} as DataSeries
  )

  return {
    dates,
    correctByDate,
    questionsByDate,
    answersByDate,
    totalTestsByDate,
    successRate,
  }
}

const StatsPage: FC = () => {
  const testPeriod = useParams().period

  const { rawStats } = useStatsContext()

  const [period, setPeriod] = useState<Period>('All')
  const [type, setType] = useState<string | undefined>(undefined)
  const [cumulative, setCumulative] = useState(false)

  const _stats = useMemo(() => {
    if (!rawStats || !testPeriod) return null
    return getStatsForPeriod(period, testPeriod, rawStats, type)
  }, [period, rawStats, testPeriod, type])

  const stats = useDeferredValue(_stats)

  const _cumulativeStats = useMemo(() => {
    if (!stats) return null
    const { dates, totalTestsByDate, correctByDate, answersByDate, questionsByDate, successRate } = stats
    return {
      dates,
      totalTestsByDate: accumulateByDate(dates, totalTestsByDate),
      correctByDate: accumulateByDate(dates, correctByDate),
      answersByDate: accumulateByDate(dates, answersByDate),
      questionsByDate: accumulateByDate(dates, questionsByDate),
      successRate: withLastByDate(dates, successRate),
    }
  }, [stats])

  const cumulativeStats = useDeferredValue(_cumulativeStats)

  const _graphs = useMemo(() => {
    const statsToUse = cumulative ? cumulativeStats : stats

    return [
      {
        big: true,
        label: 'Úspešnosť',
        series: statsToUse ? toSeries(statsToUse.dates, statsToUse.successRate) : undefined,
        type: cumulative ? 'line' : 'bar',
        scaleFormatter: (val: number) => Number((val * 100).toFixed(2)).toLocaleString() + '%',
      },
      {
        big: false,
        label: 'Počet vyplnených testov',
        series: statsToUse ? toSeries(statsToUse.dates, statsToUse.totalTestsByDate) : undefined,
        type: cumulative ? 'line' : 'bar',
      },
      {
        big: false,
        label: 'Počet zadaných otázok',
        series: statsToUse ? toSeries(statsToUse.dates, statsToUse.questionsByDate) : undefined,
        type: cumulative ? 'line' : 'bar',
      },
      {
        big: false,
        label: 'Počet zodpovedaných otázok',
        series: statsToUse ? toSeries(statsToUse.dates, statsToUse.answersByDate) : undefined,
        type: cumulative ? 'line' : 'bar',
      },
      {
        big: false,
        label: 'Počet správnych odpovedí',
        series: statsToUse ? toSeries(statsToUse.dates, statsToUse.correctByDate) : undefined,
        type: cumulative ? 'line' : 'bar',
      },
    ]
  }, [cumulative, cumulativeStats, stats])

  const graphs = useDeferredValue(_graphs)

  return (
    <div className="animate-fade-in w-full h-full items-center justify-center z-100 overflow-y-auto pt-8 px-4">
      <div className="grow w-full py-8">
        <h1 className="text-jajko-50 text-6xl font-bold uppercase text-center">Štatistiky</h1>
        <div className="flex flex-col gap-4 pt-8 items-center">
          <p className="text-kura font-medium text-xl">
            Obdobie
          </p>
          <div className="grid sm:grid-cols-2 gap-2 w-full">
            <button
              className={`py-2 rounded-lg ${period === 'Last7' ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setPeriod('Last7')}
            >
              Posledný týždeň
            </button>
            <button
              className={`py-2 rounded-lg ${period === 'Last14' ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setPeriod('Last14')}
            >
              Posledné dva týždne
            </button>
            <button
              className={`py-2 rounded-lg ${period === 'Last30' ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setPeriod('Last30')}
            >
              Posledný mesiac
            </button>
            <button
              className={`py-2 rounded-lg ${period === 'All' ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setPeriod('All')}
            >
              Celé obdobie
            </button>
          </div>
          <p className="text-kura font-medium text-xl">
            Predmet
          </p>
          <div className="grid w-full sm:grid-cols-2 [&>] gap-2">
            <button
              className={`py-2 rounded-lg ${type === 'svk-sjl' ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setType('svk-sjl')}
            >
              Slovenský jazyk a literatúra
            </button>
            <button
              className={`py-2 rounded-lg ${type === 'svk-mat' ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setType('svk-mat')}
            >
              Matematika
            </button>
            <button
              className={`py-2 rounded-lg ${type === 'svk-anjB1' ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setType('svk-anjB1')}
            >
              Anglický jazyk B1
            </button>
            <button
              className={`py-2 rounded-lg ${type === 'svk-anjB2' ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setType('svk-anjB2')}
            >
              Anglický jazyk B2
            </button>
            <button
              className={`py-2
                  col-span-1 lg:col-span-2
                rounded-lg ${type === undefined ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setType(undefined)}
            >
              Všetko
            </button>
          </div>
          <p className="text-kura font-medium text-xl">
            Kumulatívne
          </p>
          <div className="grid gap-4 sm:grid-cols-2 w-full">
            <button
              className={`py-2 rounded-lg ${cumulative ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setCumulative(true)}
            >
              Kumulatívne
            </button>
            <button
              className={`py-2 rounded-lg ${!cumulative ? 'bg-kura text-yellow-300' : 'bg-jajko text-kura'} hover:opacity-80 transition-colors border border-jajko-50`}
              onClick={() => setCumulative(false)}
            >
              Nekumulatívne
            </button>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 w-full gap-6 pt-8">
          {graphs?.map(({ big, label, series, type, scaleFormatter }) => (
            <div key={label} className={[big ? 'lg:col-span-2' : ''].asClass}>
              {series ? (
                <Graph label={label} data={[series]} type={type as 'line' | 'bar'} scaleFormatter={scaleFormatter} />
              ) : (
                <div className="bg-yellow-300 h-[400px] drop-shadow-md rounded-xl animate-pulse" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StatsPage
