import { IndexedDBProps } from "react-indexed-db-hook";

export const indexedDB: IndexedDBProps = {
  name: "KuraMaturaDB-SK",
  version: 3,
  objectStoresMeta: [
    {
      store: "previous_tests",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "taken_at", keypath: "taken_at", options: { unique: true } },
        { name: "time", keypath: "time", options: { unique: false } },
        { name: "test_name", keypath: "test_name", options: { unique: false } },
        { name: "test_url", keypath: "test_url", options: { unique: false } },
        { name: "correct_answers", keypath: "correct_answers", options: { unique: false } },
        { name: "total_answers", keypath: "total_answers", options: { unique: false } },
        { name: "test", keypath: "test", options: { unique: false } },
      ],
    },
    {
      store: "firestore_testCompletions",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        // metadata
        { name: "id", keypath: "id", options: {unique: true}},
        {
          name: "user", keypath: "user",
          options: {
            unique: false
          }
        },
        {
          name: "startedAt", keypath: "startedAt",
          options: {
            unique: false
          }
        },
        {
          name: "completedAt", keypath: "completedAt",
          options: {
            unique: false
          }
        },
        {
          name: "version", keypath: "version",
          options: {
            unique: false
          }
        },
        // subscription
        {
          name: "testPeriod", keypath: "testPeriod",
          options: {
            unique: false
          }
        },
        // test specific
        {
          name: "name", keypath: "name",
          options: {
            unique: false
          }
        },
        {
          name: "year", keypath: "year",
          options: {
            unique: false
          }
        },
        {
          name: "language", keypath: "language",
          options: {
            unique: false
          }
        },
        {
          name: "type", keypath: "type",
          options: {
            unique: false
          }
        },
        // answers
        {
          name: "answers", keypath: "answers",
          options: {
            unique: false
          }
        }
      ]
    }
  ],
};