import { isCorrect } from 'helpers'
import { FC, useState } from 'react'
import { ITFSAnswered, ITFSUnanswered } from 'types/tests/anj'

interface props {
  id: string
  number: number
  question: ITFSAnswered | ITFSUnanswered
  showResult?: boolean
}

const TFSQuestion: FC<props> = ({ id, number, question, showResult }) => {
  const correct = showResult && question.answered && isCorrect(question)
  const _answer = question.answered && question.answer?.split?.("-") as [string, string]
  const _answerTF = _answer && _answer[0]
  const _answerSupport = _answer && _answer[1]
  const correctSplit = question.correctAnswer?.split("-") as [string, string]
  const _answerTFCorrect = _answerTF === correctSplit[0]
  const _answerSupportCorrect = _answerSupport === correctSplit[1]
  const [tf, setTF] = useState<string>('')
  const [support, setSupport] = useState('')

  const correctClass = showResult && 'text-green-600 border-green-600 border-2 bg-green-50 cursor-default'
  const incorrectClass = showResult && 'text-rose-600 border-rose-600 border-2 bg-rose-50 cursor-default'
  const showResultInputClass = showResult ? (_answerSupportCorrect ? 'text-green-600 border-green-600 bg-green-50' : 'text-rose-600 border-rose-600 bg-rose-50') : null

  const numberClass = showResult
    ? correct
      ? 'ring-offset-2 ring-2 ring-green-600 rounded bg-green-600 w-8 shrink-0 text-center text-black select-none'
      : 'ring-offset-2 ring-2 ring-rose-600 rounded bg-rose-600 w-8 shrink-0 text-center text-black select-none'
    : 'ring-offset-2 ring-2 ring-jajko rounded bg-jajko w-8 shrink-0 text-center text-black select-none '

  return (
    <div
      style={{ borderLeft: !showResult ? undefined : correct ? '4px solid green' : '4px solid red' }}
      id={id}
      data-type="question"
      data-qtype={question.type}
      data-number={number}
      data-answer={`${tf}-${support}`}
      className="flex flex-col w-full items-start space-x-2 px-5 justify-start pb-5"
    >
      <div className="flex items-start space-x-4 py-4">
        <span className={numberClass}>{number}</span>
        <span className="font-semibold -mt-0 select-none" dangerouslySetInnerHTML={{ __html: question.question }} />
      </div>
      <div className="flex items-center justify-evenly mt-2">
        {['true', 'false'].map((choice: string, l: number) => (
          <div key={`${id}_${choice}`} className="flex items-start mb-2 px-10">
            <input
              disabled={showResult}
              id={`${id}_${choice}`}
              onInput={(e) => setTF(e.currentTarget.checked ? e.currentTarget.value : '')}
              name={`${id}_tf`}
              type="radio"
              className="peer disabled:hidden cursor-pointer h-4 w-4 mt-1 accent-kura shrink-0"
              value={l}
            />
            <label
              htmlFor={`${id}_${choice}`}
              dangerouslySetInnerHTML={{ __html: choice }}
              className={
                [
                'peer-checked:font-bold select-none cursor-pointer rounded px-2',
                  !showResult
                    ? "peer-checked:text-kura"
                    : _answerTFCorrect && _answerTF && parseInt(_answerTF, 10) === l
                    ? correctClass
                    : !_answerTFCorrect && _answerTF && parseInt(_answerTF, 10) === l
                    ? incorrectClass
                    : !_answerTFCorrect && (question.correctAnswer as any).includes(l)
                    ? [correctClass, 'border-0'].asClass
                    : undefined
                , showResult && "cursor-default"].asClass
              }
            />
          </div>
        ))}
      </div>
      <div className="pl-10 flex items-center flex-wrap gap-2">
        <span>
          Which of the paragraphs <b>(a)–(e)</b> supports your answer?
        </span>
        <div className="flex items-center gap-2">
        <input type="text" autoComplete="new-password" maxLength={1} disabled={showResult} className={['border-2 border-jajko px-2 py-1 w-10 text-center outline-none rounded', showResultInputClass].asClass} onChange={(e) => setSupport(e.currentTarget.value)} />
        {showResult && !_answerSupportCorrect && <span className="text-rose-600">Správna odpoveď: {correctSplit[1]}</span>}
        </div>
      </div>
      <input type="text" className="hidden" value={`${tf}-${support}`} onChange={() => {}} />
    </div>
  )
}

export default TFSQuestion
