export const testAnalysisPeriods = [
  "2024_25",
];

export const testAnalysisPeriodsByProduct = {
  "kuramatura_sk_analyzy_2024_25": "2024_25",
};
export const currentTestAnalysisProduct = "kuramatura_sk_analyzy_2024_25"

export const currentTestAnalysisPeriod = "2024_25"

export const analysisPeriods = [
  {
    id: "2024_25",
    title: "2024/25"
  }
]

export const metadataByProduct = {
  kuramatura_sk_analyzy_2024_25: {
    analysisPeriod: "2024_25",
    price: "10.00€"
  }
}
