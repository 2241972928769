import { useEffect } from "react"

const useTitle = (title?: string, description?: string) => {

    useEffect(() => {
        if (!title) return
        const prev = document.title
        document.title = `Kura Matura - ${title}`
        return () => {
            document.title = prev
        }
    }, [title])

    useEffect(() => {
        if (!description) return
        const prev = document.querySelector('meta[name="description"]')?.getAttribute('content')!
        document.querySelector('meta[name="description"]')?.setAttribute('content', description)
        return () => {
            document.querySelector('meta[name="description"]')?.setAttribute('content', prev)
        }
    }, [description])
}

export default useTitle