import { isCorrect } from 'helpers'
import { FC, useLayoutEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { IMissingFreeAnswered, IMissingFreeUnanswered } from 'types/tests/anj'

interface props {
  id: string
  number: number
  question: IMissingFreeAnswered | IMissingFreeUnanswered
  showResult?: boolean
}

const MissingFreeQuestion: FC<props> = ({ id, number, question, showResult }) => {
  const correct = showResult && question.answered && isCorrect(question)
  const [value, setValue] = useState('')
  const [placeholder, setPlaceholder] = useState('')

  const [anchor, setAnchor] = useState<Element | null>(null)

  useLayoutEffect(() => {
    const el = document.getElementById(`anchor-${number}`)
    if (el) {
      const pl = el.innerText
      if (pl) setPlaceholder(pl)
      setAnchor(el)
      const wrapper = document.createElement('span')
      el.innerText = ''
      wrapper.style.display = 'none'
      wrapper.innerText = pl
      el.appendChild(wrapper)
    } else {
      setAnchor(null)
    }
  }, [number])

  const incorrectClass = showResult && 'text-rose-600 rounded line-through bg-rose-50 px-1'

  return (
    <div id={id} data-type="question" data-answer={value} data-qtype={question.type} data-number={number} className="flex flex-col w-full items-start space-x-2 px-5 justify-start pb-5 hidden">
      {anchor &&
        createPortal(
          <span data-qtype={question.type} className="ml-1" data-number={number}>
            <input
              type="text"
                autoComplete="new-password"
              className={showResult ? 'hidden' : 'px-1 bg-jajko rounded ring-2 ring-offset-2 ring-jajko text-black mx-1 text-base w-40 outline-none placeholder:text-white placeholder-opacity-50'}
              value={value}
              placeholder={placeholder}
              onChange={(e) => setValue(e.currentTarget.value)}
              onBlur={() => setValue((old) => old.trim())}
            />
            {showResult && (
              <span className={[showResult ? '' : 'hidden'].asClass}>
                {!correct ? <span className={[incorrectClass, 'font-bold mr-2'].asClass}>{question.answered && question.answer ? question.answer : '    '}</span> : null}
                <span className="font-bold text-green-600 bg-green-100 px-1 rounded">{question.correctAnswer[0]}</span>
              </span>
            )}
          </span>,
          anchor
        )}
      <input type="text" className="hidden" value={value} onChange={() => {}} />
    </div>
  )
}

export default MissingFreeQuestion
