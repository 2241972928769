import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { IAnswered, IQuestion, ITest, ITestPartNotStarted, ITestPartStarted, ITestSectionNotStarted, ITestSectionStarted, ITestStarted } from 'types/tests'
import testContext from './context'
import { useLocation } from 'react-router'
import useTimer from 'hooks/useTimer'
import { findParentQuestionOfInput, getQuestions, isCorrect, saveTestCompletion } from 'helpers'
import { usePreviousTestsDB } from 'modules/previousTests/hook'
import metaPixel from 'helpers/metaPixel'
import firebase from 'config/firebase'
import { logEvent } from 'firebase/analytics'

const baseQuestionToUnanswered = (question: IQuestion) => {
  return { ...question, answered: false as const as any, answer: null } as IAnswered
}

const basePartToUnanswered = (part: ITestPartNotStarted) => {
  return { ...part, started: true as const, questions: part.questions.map(baseQuestionToUnanswered) } as ITestPartStarted
}

const baseSectionToUnanswered = (section: ITestSectionNotStarted | ITestSectionStarted) => {
  if (section.started) return section
  return { ...section, started: true as const, parts: section.parts.map(basePartToUnanswered) } as ITestSectionStarted
}

const useSelectedTest = () => {
  const [test, setTest] = useState<ITest | null>(null)
  const previousDB = usePreviousTestsDB()
  const [answers, setAnswers] = useState<(string | number | null)[]>([])
  const location = useLocation()
  const pathname = location.pathname
  const pathSplit = pathname.split('/').slice(1)
  const isTestPicked = pathSplit.length === 4
  const previous = location.state?.previous

  useEffect(() => {
    if (isTestPicked) {
      if (previous) {
        previousDB.getByID(previous).then((test) => {
          if (test) {
            setTest({...test.test, ended: true, endTime: new Date((new Date(test.test.startTime).getTime() + test.time))})
            setAnswers(getQuestions(test.test).map((q) => (q.answered ? q.answer : null)))
          }
        })
      }
      else {
      fetch(`/assets/${window.location.pathname.split('/').slice(1).join('/')}/test.json`)
        .then((res) =>
          res.json().then((res: ITest) => {
            setTest(res)
            setAnswers(getQuestions(res).map((q) => (q.answered ? q.answer : null)))
          })
        )
        .catch((err) => console.log(err))
      }
    } else {
      setTest(null)
    }
  }, [isTestPicked, previous, previousDB.getByID])

  return { test, answers, setTest, setAnswers }
}

const TestProvider: FC<PropsWithChildren> = ({ children }) => {
  const {test, answers, setTest, setAnswers} = useSelectedTest()
  const previousDB = usePreviousTestsDB()
  const endsAt = test && test.startTime ? (!test.endTime ? new Date(test.startTime).getTime() + test.duration * 60000 : new Date(test.endTime).getTime()) : null
  const timeLeft = useTimer(endsAt)

  const start = useCallback(() => {
    if (!test) return
    metaPixel.trackCustom("TestStart", {test: `${test.type}_${test.year}`, value: 1})
    logEvent(firebase.analytics, 'test_start', {test: `${test.type}_${test.year}`})
    setTest((prev) => prev && { ...prev, sections: prev.sections.map(baseSectionToUnanswered), started: true, startTime: new Date() })
  }, [test, setTest])

  const end = useCallback(async () => {
    if (!test || !test.started || test.ended) return
    const answeredPercentage = (answers?.reduce((acc: number, val) => acc + (val !== null ? 1 : 0), 0)) / answers.length
    metaPixel.trackCustom("TestEnd", {test: `${test.type}_${test.year}`, value: answeredPercentage})
    logEvent(firebase.analytics, 'test_end', {test: `${test.type}_${test.year}`, value: answeredPercentage * 100})
    const endTime = new Date()
    await saveTestCompletion({...test, ended: true, endTime})
    setTest((prev) => prev && ({...prev, ended: true, endTime}))
    previousDB.add({test: {...test, ended: true, endTime}, taken_at: new Date(test.startTime).toISOString(), time: endTime.getTime() - test.startTime.getTime() , test_name: `${test.name} - ${test.year}`, test_url: window.location.pathname, correct_answers: getQuestions(test).map((q) => q.answered && isCorrect(q)).filter(Boolean).length, total_answers: answers.length })
  }, [test, answers, previousDB, setTest])

  useEffect(() => {
    if (!test) return
    if (test.started && !test.ended && timeLeft === 0) {
      end()
    }
  }, [end, test, timeLeft, endsAt])

  const changeAnswer = useCallback((sectionIndex: number, partIndex: number, questionIndex: number, answer: number | string | null) => {
    try {
      metaPixel.trackCustom("TestAnswer", {test: `${test?.type}_${test?.year}`, value: 1})
      logEvent(firebase.analytics, 'test_answer', {test: `${test?.type}_${test?.year}`})
    } catch (e) {
      console.log(e)
    }
    setTest((prev) => {
      if (prev) {
        const q = prev.sections[sectionIndex].parts[partIndex].questions[questionIndex]
        prev.sections[sectionIndex].parts[partIndex].questions[questionIndex] = { ...q, answer: answer as any, answered: answer !== null && answer !== undefined }
        setAnswers(getQuestions(prev).map((q) => (q.answered ? q.answer : null)))
      }
      return prev
    })
  }, [setTest, setAnswers, test?.year, test?.type])

  useEffect(() => {
    if (!test) return
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const el = mutation.target as HTMLElement
        if (el.tagName !== 'DIV') return
        const question = findParentQuestionOfInput(el as HTMLInputElement)
        if (!question) return
        const inputs = question.getElementsByTagName('input')
        const [_, s, p, q] = question.id?.split('_') || []
        if (inputs.length === 0) return
        if (inputs.length === 1 && inputs[0].type === 'text') {
          changeAnswer(Number(s), Number(p), Number(q), inputs[0].value || null)
        } else {
          const qtype = el.getAttribute('data-qtype')
          if (['tfs', 'fill-free'].includes(qtype || '')) {
            const answer = el.getAttribute('data-answer')
            changeAnswer(Number(s), Number(p), Number(q), answer || null)
          } else {
            changeAnswer(Number(s), Number(p), Number(q), Number(Array.from(inputs).find((input) => input.checked)?.value))
          }
        }
      })
    })

    mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['data-answer'],
      subtree: true,
      childList: true,
    })

    return () => mutationObserver.disconnect()
  }, [test, changeAnswer])

  return <testContext.Provider value={{ data: test, start, end, timeLeft, started: Boolean(test?.started), ended: Boolean(test?.started && test.ended), answers }}>{children}</testContext.Provider>
}

export default TestProvider
