import { isCorrect } from 'helpers'
import { FC, Fragment, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { IFillFreeAnswered, IFillFreeUnanswered } from 'types/tests/anj'

interface props {
  id: string
  number: number
  question: IFillFreeAnswered | IFillFreeUnanswered
  showResult?: boolean
}

const FillFreeQuestion: FC<props> = ({ id, number, question, showResult }) => {
  const correct = showResult && question.answered && isCorrect(question)
  const [values, _setValues] = useState<string[]>([])
  const answerValues = question.answered ? question.answer?.split(' ') : []
  const correctValues = question.correctAnswer[0].split(' ')
  const placeholders = useRef<HTMLSpanElement[]>([])

  const setValue = (index: number, value: string) => {
    _setValues((old) => {
      const newValues = [...old]
      while (newValues.length < index + 1) {
        newValues.push('')
      }
      newValues[index] = value
      return newValues
    })
  }

  const incorrectClass = showResult && 'text-rose-600 rounded line-through bg-rose-50 px-1'

  const numberClass = showResult
    ? correct
      ? 'ring-offset-2 ring-2 ring-green-600 rounded bg-green-600 w-8 shrink-0 text-center text-black select-none'
      : 'ring-offset-2 ring-2 ring-rose-600 rounded bg-rose-600 w-8 shrink-0 text-center text-black select-none'
    : 'ring-offset-2 ring-2 ring-jajko rounded bg-jajko w-8 shrink-0 text-center text-black select-none'

  return (
    <div
      style={{ borderLeft: !showResult ? undefined : correct ? '4px solid green' : '4px solid red' }}
      id={id}
      data-type="question"
      data-qtype={question.type}
      data-number={number}
      data-answer={values.join(' ').trim()}
      className="flex flex-col w-full items-start space-x-2 px-5 justify-start"
    >
      <div
        className="flex items-start space-x-4 py-4"
        ref={(e) => {
          if (placeholders.current.length > 0) return
          const els = Array.from(e?.children[1]?.getElementsByClassName('placeholder') || [])
          placeholders.current = els as HTMLSpanElement[]
        }}
      >
        <span className={numberClass}>{number}</span>
        <span className="font-medium -mt-0 select-none mobile:leading-8 mobile:space-x-1" dangerouslySetInnerHTML={{ __html: question.question }} />
      </div>
      {placeholders.current.map((el, i) => {
        const isCorrect = showResult && values[i] && values[i].trim() === correctValues[i]

        return (
          <Fragment key={i}>
            {createPortal(
              showResult ? (
                <span className={[showResult ? '' : 'hidden'].asClass}>
                  {!isCorrect ? <span className={[incorrectClass, 'font-bold mr-2'].asClass}>{answerValues?.[i] ? answerValues[i] : '    '}</span> : null}
                  <span className="font-bold text-green-600 bg-green-100 px-1 rounded">{correctValues[i]}</span>
                </span>
              ) : (
                <input
                  type="text"
                  className="px-1 bg-jajko rounded ring-2 ring-offset-2 ring-jajko text-black mx-1 text-base w-40 outline-none"
                  value={values[i] || ''}
                  autoComplete="new-password"
                  onChange={(e) => setValue(i, e.currentTarget.value)}
                  onBlur={(e) => setValue(i, e.currentTarget.value.trim())}
                />
              ),
              el
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default FillFreeQuestion
