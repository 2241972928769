import { onAuthStateChanged, User, isSignInWithEmailLink, signInWithEmailLink as _signInWithEmailLink, sendSignInLinkToEmail } from 'firebase/auth'
import { useState, useEffect, FC, PropsWithChildren, useCallback } from "react"
import firebase from "../../config/firebase"
import AuthContext from "./authContext"
import AuthModalContent from 'components/modals/AuthModal'
import SigningInLoaderModal from 'components/modals/SigningInLoaderModal'
import { IUser } from 'types/users'
import { doc, onSnapshot } from 'firebase/firestore'

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>()
  const [profile, setProfile] = useState<IUser | null>()
  const [signingIn, setSigningIn] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebase.auth, async (user) => {
      if (user === null) {
        if (isSignInWithEmailLink(firebase.auth, window.location.href)) {
          const email = window.localStorage.getItem('emailForSignIn')
          if (!email) {
            window.alert('Email nenájdený')
          }
          setSigningIn(true)
          await _signInWithEmailLink(firebase.auth, email!, window.location.href).then(() => {
            window.history.replaceState({}, document.title, window.location.pathname)
            window.localStorage.removeItem('emailForSignIn')
          }).finally(() => {
            setSigningIn(false)
          })
        } else {
          setUser(null)
        }
      } else {
        setModalOpen(false)
        setUser(user)
      }
    })

    return () => unsubscribe()
  }, [])

  const signInWithEmailLink = useCallback(async (email: string) => {
    return sendSignInLinkToEmail(firebase.auth, email, { handleCodeInApp: true, url: window.location.href }).then(() => {
      window.localStorage.setItem('emailForSignIn', email)
    })
  }, [])

  useEffect(() => {
    if (!user?.uid) {
      setProfile(null)
    } else {
      const unsubscribe = onSnapshot(doc(firebase.firestore, "users", user.uid), (snapshot) => {
        setProfile(snapshot.data() as IUser || null)
      })

      return () => unsubscribe()
    }
  }, [user?.uid])

  return (
    <AuthContext.Provider value={{ user, profile, beginSignInWithEmail: () => setModalOpen(true) }}>
        <AuthModalContent open={modalOpen} close={() => setModalOpen(false)} signInWithEmailLink={signInWithEmailLink} />
          <SigningInLoaderModal open={signingIn} />
        {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider