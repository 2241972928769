import { FC } from 'react'
import { Chart, UserSerie } from 'react-charts'

export interface DataPoint {
  date: string
  value: number
}

interface Props {
  data: UserSerie<DataPoint>[]
  label: string
  type?: 'line' | 'bar'
  scaleFormatter?: (val: number) => string
}

const Graph: FC<Props> = ({ data, type = 'line', label, scaleFormatter = (val: number) => val?.toString() }) => {
  return (
    <div className="flex flex-col gap-4 w-full bg-yellow-200 rounded-xl p-2 drop-shadow-md">
      <div className="relative w-full h-[400px]">
          <Chart
            className="h-[400px] bg-yellow-100 rounded-xl w-full"
            options={{
              initialHeight: 400,
              initialWidth: 400,
              padding: 16,
              tooltip: false,
              data,
              primaryAxis: {
                getValue: ({ date }) => new Date(date).toLocaleDateString(),
                scaleType: "band",
              },
              secondaryAxes: [
                {
                  elementType: type,
                  formatters: {
                    scale: scaleFormatter,
                  },
                  getValue: ({ value }) => value,
                  max: 1,
                  hardMin: 0
                },
              ],
              getSeriesStyle: () => {
                return {
                  color: '#EC6730',
                }
              },
            }}
          />
      </div>
      <span className="text-xl font-medium text-kura text-center pb-2">{label}</span>
    </div>
  )
}

export default Graph
