import { isCorrect } from 'helpers'
import { FC } from 'react'
import { IOrderAnswered, IOrderUnanswered } from 'types/tests/anj'

interface props {
  id: string
  number: number
  question: IOrderAnswered | IOrderUnanswered
  showResult?: boolean
}

const OrderQuestion: FC<props> = ({ id, number, question, showResult }) => {
  const correct = showResult && question.answered && isCorrect(question)
  const showResultWrapperClass = showResult ? correct ? 'border-l-4 border-l-green-500' : 'border-l-4 border-l-rose-500' : null
  const showResultInputClass = showResult ? correct ? 'text-green-600 border-green-600 bg-green-50' : 'text-rose-600 border-rose-600 bg-rose-50' : null

  const numberClass = showResult
    ? correct
      ? 'ring-offset-2 ring-2 ring-green-600 rounded bg-green-600 w-8 shrink-0 text-center text-black select-none'
      : 'ring-offset-2 ring-2 ring-rose-600 rounded bg-rose-600 w-8 shrink-0 text-center text-black select-none'
    : 'ring-offset-2 ring-2 ring-jajko rounded bg-jajko w-8 shrink-0 text-center text-black select-none'

  return (
    <div
      key={id}
      id={id}
      data-type="question"
            data-qtype={question.type}
data-number={number}
      className={["flex w-full items-center space-x-2 px-5 justify-start pb-1 -mt-4", showResultWrapperClass].asClass}
    >
      <div className="flex items-start space-x-4 py-4 grow">
        <span className={numberClass}>{number}</span>
        <span className="font-semibold -mt-0 select-none" dangerouslySetInnerHTML={{ __html: question.question }} />
      </div>
      <div className="px-10 py-2 border-l-2">
        <input
          disabled={showResult}
          name={id}
          onBlur={(e) => {
            e.currentTarget.value = e.currentTarget.value.trim()
            e.currentTarget.closest('[data-type="question"]')?.setAttribute('data-answer', e.currentTarget.value)
          }}
          onInput={(e) => e.currentTarget.closest('[data-type="question"]')?.setAttribute('data-answer', e.currentTarget.value)}
          type="text"
          autoComplete="new-password"
          className={["border-2 border-jajko px-2 py-1 w-20 outline-none rounded", showResultInputClass].asClass}
        />
        {showResult && !correct && (
          <div className="text-rose-500 font-medium text-sm mt-1">
            {question.correctAnswer ? `Správna odpoveď: ${question.correctAnswer}` : 'Nemá správnu odpoveď'}
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderQuestion
