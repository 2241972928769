import useTestContext from "contexts/TestContext/hook";
import { getQuestions, isCorrect } from "helpers";
import { FC, useEffect, useState } from "react";
import { IAnswered, IQuestion } from "types/tests";

const getColorByQuestion = (q: IAnswered | IQuestion, ended: boolean) => {
    if (ended) {
        if (q.answered && isCorrect(q)) return "bg-green-500 text-white"
        if (q.answered && !isCorrect(q)) return "bg-red-500 text-white"
        return "bg-jajko text-kura"
    } else {
        if (q.answered && q.answer !== null) return "bg-kura text-yellow-300"
        return "bg-jajko text-kura"
    }
}

const QuestionList: FC<{hideSidebar: () => void}> = ({hideSidebar}) => {
    const {data, ended, started} = useTestContext()
    const questions = data ? getQuestions(data) : []
    const [activeQuestion, setActiveQuestion] = useState(0)

    useEffect(() => {
        if (!data) {
            setActiveQuestion(0)
        } else {
            const onScroll = () => {
                const qEl = document.querySelectorAll(`[data-number]`)
                const qElArr = Array.from(qEl)
                const qElArrSorted = qElArr.sort((a, b) => {
                    const aRect = a.getBoundingClientRect().top
                    const bRect = b.getBoundingClientRect().top
                    if (aRect < 0) return -1
                    if (bRect < 0) return -1
                    return aRect - bRect
                }
                )
                setActiveQuestion(parseInt(qElArrSorted[0]?.getAttribute("data-number") || "0") - 1)
            }
            const test = document.getElementById("test")?.parentElement
            if (!test) return 
            test.addEventListener("scroll", onScroll)

            return () => {
                test.removeEventListener("scroll", onScroll)
            }
        }
    }, [data])

    return (
        <div className="grid grid-cols-6 gap-2 mt-2 -mb-4 overflow-y-auto">
            {questions.map((q, i) => {
                return (
                <div
                    key={`q_${i}`}
                    className={["rounded hover:-translate-y-px h-max transition-all cursor-pointer select-none px-0.5 text-center font-medium text-base py-1 leading-none", getColorByQuestion(q, ended)].asClass}
                    onClick={() => {
                        if (started) {
                            const qEl = document.querySelector(`[data-number="${i+1}"]`)
                            const test = document.getElementById("test") as HTMLDivElement
                            if (test && qEl) {
                                test.scrollTo({top: qEl.getBoundingClientRect().top + test.scrollTop});
                                hideSidebar();
                            }
                        }
                    }}
                >
                    {(i + 1).toString().padStart(2, "0")}
                </div>
            )})}
        </div>
    )
}

export default QuestionList