const bannerId = "73217"
const affiliateId = "22297"

export class AlzaClick {
    static openLink() {
        const lastOpened = localStorage.getItem('alzaClick')
        // only open once every 2 weeks
        if (lastOpened && Date.now() - parseInt(lastOpened) < 1000 * 60 * 60 * 24 * 14) {
            return
        }
        localStorage.setItem('alzaClick', Date.now().toString())
        const base = 'https://www.alza.sk'
        const sp = new URLSearchParams()
        sp.append('IDP', affiliateId)
        sp.append("banner_id", bannerId)
        window.open(`${base}?${sp.toString()}`, '_blank')
    }

    static bannerClicked() {
        localStorage.setItem('alzaClick', Date.now().toString())
    }
}