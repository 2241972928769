import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router'
import MainLayout from './layout/MainLayout'
import Test from 'modules/tests'
import { useEffect } from 'react'
import TestPicker from 'layout/TestPicker'
import isMobile from 'helpers/isMobile'
import { initDB } from 'react-indexed-db-hook'
import { indexedDB } from 'modules/db/config'
import LandingPage from 'pages/landing'
import PreviousTestsPage from 'pages/previous'
import AboutUsPage from 'pages/about'
import ShortsPicker from 'layout/ShortsPicker'
import ShortsPage from 'pages/shorts'
import StatsPicker from 'layout/StatsPicker'
import StatsPage from 'pages/stats'

initDB(indexedDB);

function Root() {
  const location = useLocation()

  useEffect(() => {
    if (window.location.pathname !== "/tests/svk") return document.getElementById('root')?.classList.add('loaded')
    document.getElementById('root')?.classList.add('initial-load')

    const timeout = setTimeout(() => {
      document.getElementById('root')?.classList.replace('initial-load', 'loaded')
      setTimeout(() => document.body.getAnimations({subtree: true}).forEach((anim) => anim.finish()), 0)
    }, 4000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (!isMobile()) return
    const styleSheets = Array.from(document.styleSheets)
    styleSheets.forEach((styleSheet) => {
      if (styleSheet.ownerNode?.nodeName === "STYLE") {
        const rules = Array.from(styleSheet.cssRules)
        rules.forEach((rule) => {
          if (rule instanceof CSSStyleRule) {
            if (rule.selectorText.includes(":hover")) {
              rule.selectorText = rule.selectorText.replace(":hover", ":active")
            }
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    // @ts-ignore
    fbq('track', "PageView");
  }, [location])

  useEffect(() => {
    const onMutation = (mutations: MutationRecord[]) => {
      mutations.forEach((mutation) => {
          // @ts-ignore
          renderMathInElement(mutation.target, {
            throwOnError: false,
          })
        })
    }

    const observer = new MutationObserver(onMutation)
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Routes>
      <Route path="*" element={<MainLayout />}>
        <Route index element={<LandingPage />} />
        <Route path="tests" element={<TestPicker />}>
          <Route index element={<Navigate to="/tests/svk" replace />} />
          <Route path=":language" element={<Outlet />}>
            <Route index element={<Outlet />} />
            <Route path=":subject" element={<Outlet />}>
              <Route index element={<Outlet />} />
              <Route path=":year" element={<Test />} />
            </Route>
          </Route>
        </Route>
        <Route path="shorts" element={<ShortsPicker />}>
          <Route index element={<Outlet />} />
          <Route path=":subject" element={<Outlet />}>
            <Route index element={<ShortsPage />} />
          </Route>
        </Route>
        <Route path="stats" element={<StatsPicker />}>
          <Route index element={<Outlet />} />
          <Route path=":period" element={<Outlet />}>
            <Route index element={<StatsPage />} />
          </Route>
        </Route>
        <Route path="previous" element={<PreviousTestsPage />} />
        <Route path="about" element={<AboutUsPage />} />
      </Route>
    </Routes>
  )
}

export default Root
