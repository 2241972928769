import { FC, useMemo, useRef, useState } from 'react'
import Header from './Header'
import { Outlet, useNavigate, useParams } from 'react-router'
import useTitle from 'hooks/useTitle'
import { analysisPeriods, currentTestAnalysisPeriod, currentTestAnalysisProduct, metadataByProduct } from 'constants/payments'
import useAuthContext from 'contexts/Auth/useAuthContext'
import PaymentModal from 'components/modals/PaymentModal'

const StatsPicker: FC = () => {
  const { profile, user, beginSignInWithEmail } = useAuthContext()

  const periodId = useParams().period
  const period = analysisPeriods.find(({ id }) => id === periodId)
  const navigate = useNavigate()
  const wrapper = useRef<HTMLDivElement>(null)
  
  const [payment, setPayment] = useState<string>()

  const periodHidden = !!period

  const nothingShown = periodHidden

  const title = period ? `${period.title} - Štatistiky` : 'Štatistiky'

  const profilePeriods = useMemo(() => {
    if (!profile) return undefined
    return [...(profile?.testPeriods || []).filter((p) => p !== currentTestAnalysisPeriod), currentTestAnalysisPeriod].slice(0).reverse()
  }, [profile])

  useTitle(title)

  if (user === undefined) {
    return (
      <div className="animate-fade-in flex flex-col h-full items-center justify-center z-100 overflow-hidden py-8">
        <div className="flex flex-col gap-4 py-8">
            <span className="text-kura text-center font-medium w-full text-lg">
                Načítava sa...
            </span>
        </div>
      </div>
    )
  }

  if (!user) {
    return (
      <div className="animate-fade-in flex flex-col h-full items-center justify-center z-100 overflow-hidden py-8">
        <div className="flex flex-col gap-4 py-8">
            <span className="text-kura text-center font-medium w-full text-lg">
                Pre prístup k štatistikám sa prihláste.
            </span>
            <button className="button-primary" onClick={beginSignInWithEmail}>
                Prihlásiť sa
            </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="absolute inset-0 z-[10]" style={period ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }}>
        <Outlet />
      </div>
      <PaymentModal open={!!payment} close={() => setPayment(undefined)} link={payment} />
      <div
        ref={wrapper}
        className={
          ['flex transition-all flex-col pt-[calc(93px+1.5rem)] mobile:pt-[calc(10rem)] items-center h-0 overflow-visible animate-fade-in-delay', periodHidden && 'pointer-events-none'].asClass
        }
      >
        <div className={['transition-all', periodHidden && 'opacity-0'].asClass}>
          <Header pick={period?.title && ' '} hideDubky={periodHidden} />
        </div>
        <div
          ref={(e) => {
            if (!e) return
            if (!periodHidden) {
              e.classList.add('in')
              e.classList.remove('out')
            } else {
              !nothingShown && e.classList.contains('in') && e.classList.remove('in')
              !e.classList.contains('out') && e.classList.add('out')
            }
          }}
          className={['flex flex-col gap-4 mt-8 items-center pb-8 animate-subj', period && 'pointer-events-none'].asClass}
        >
          {profilePeriods?.map((period, i) => {
            const periodData = analysisPeriods.find(({id}) => id === period)
            const showPayment = period === currentTestAnalysisPeriod && period && !profile?.testPeriods.includes(period)
            const priceData = metadataByProduct[currentTestAnalysisProduct]
            return (
              <button
                key={period}
                ref={(e) => {
                  if (!e) return
                  e.style.setProperty('--subj-index', `${i}`)
                }}
                className={['button-primary px-6 w-max'].asClass}
                onClick={() => {
                  if (showPayment) {
                    const url = ["https://kuramatura.sk/checkout/stripe", profile?.id, currentTestAnalysisProduct].join("/")
                    setPayment(url)
                  } else {
                    navigate("./"+period, {relative: "path"})
                  }
                }}
              >
                {periodData?.title || ''} {showPayment && <b>({priceData.price})</b>}
              </button>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default StatsPicker
