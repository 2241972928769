import { LogoTextVertical } from "assets";
import { FC } from "react";
import { useNavigate } from "react-router";

const LandingPage: FC = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full h-full flex flex-col items-center justify-end px-8 select-none min-h-full h-max gap-5 mobile:pt-10 mobileWidth:pt-10 py-5">
            <LogoTextVertical className="w-16 min-w-16 opacity-0 shrink-0 mobile:hidden mobileWidth:hidden" />
            <div className="max-w-[600px] mobile:max-w-full mobileWidth:max-w-full flex flex-col items-center justify-center gap-8 min-h-max h-full grow">
                <h3 style={{animation: "fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1)", animationFillMode: "forwards"}} className="text-center text-3xl font-semibold text-kura opacity-0">
                    Interaktívna Maturitná príprava
                </h3>
                <h3 style={{animation: "fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0.5s", animationFillMode: "forwards"}} className="text-center opacity-0 text-xl text-kura opacity-0 leading-[110%] py-3">
                    Rýchlo. Interaktívne. Efektívne.<br />
                    Bez zbytočných papierov. <br />
                    Sleduj svoj pokrok, zlepši sa!
                </h3>
                <h1 className="text-4xl text-kura uppercase text-center font-bold leading-[140%]">
                    <span style={{animation: "fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1) 1s", animationFillMode: "forwards"}} className="delay-[400ms] opacity-0">Netlačíš. </span>
                    <span style={{animation: "fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1) 1.5s", animationFillMode: "forwards"}} className="delay-[400ms] opacity-0">Neplačeš. </span>
                    <span style={{animation: "fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1) 2s", animationFillMode: "forwards"}} className="delay-[400ms] opacity-0">Testuješ.</span>
                    </h1>
                <button style={{animation: "fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1) 2.5s", animationFillMode: "forwards"}} className="button-primary opacity-0 text-2xl py-3 font-bold bg-kura text-yellow-300 px-12 w-max" onClick={() => navigate("/tests")}>
                Testuj
                </button>
            </div>
            <LogoTextVertical className="w-16 min-w-16 shrink-0" />
        </div>
    )
}

export default LandingPage;