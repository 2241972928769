import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore, persistentLocalCache } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD7te9JCNNs_uu3tULYhN6N_A1G9Ss7TWk",
  authDomain: "kuramatura-7a9ba.firebaseapp.com",
  projectId: "kuramatura-7a9ba",
  storageBucket: "kuramatura-7a9ba.appspot.com",
  messagingSenderId: "443202482105",
  appId: "1:443202482105:web:aa55968051f16ee0c51068",
  measurementId: "G-XMCERXBMJ6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const firestore = initializeFirestore(app, {
  localCache: persistentLocalCache()
})

const firebase = {
    app,
    analytics,
    auth,
    firestore,
}

export default firebase;