import { isCorrect } from 'helpers'
import { FC, useEffect, useMemo } from 'react'
import { IFreeResponseAnswered, IFreeResponseQuestionUnanswered } from 'types/tests/sjl'

interface props {
  id: string
  number: number
  question: IFreeResponseAnswered | IFreeResponseQuestionUnanswered
  showResult?: boolean
}

const QuestionRenderer: FC<{question: string}> = ({question}) => {
  const element = useMemo(() => {
    const element = document.createElement("span")
    element.innerHTML = question
    // if has images replace
    const images = element.getElementsByTagName("img")
    Array.from(images).forEach((img) => {
      const wrapper = document.createElement("div")
      wrapper.className = "w-full bg-white flex justify-center py-5 relative pb-10 px-5 border-b border-b-slate-200"
      const image = document.createElement("img")
      image.className = "w-full mobile:max-w-none mobileWidth:max-w-none max-w-max"
      image.src = img.src
      wrapper.appendChild(image)
      const overlay = document.createElement("div")
      overlay.className = "absolute bg-back mix-blend-multiply inset-0"
      wrapper.appendChild(overlay)
      img?.replaceWith(wrapper)

    })
    return element.innerHTML
  }, [question])

  return <span dangerouslySetInnerHTML={{__html: element}} />
}

const FreeResponseQuestion: FC<props> = ({ id, number, question, showResult }) => {
  const correct = showResult && question.answered && isCorrect(question)
  const showResultWrapperClass = showResult ? (correct ? 'border-l-4 border-l-green-500' : 'border-l-4 border-l-rose-500') : null
  const showResultInputClass = showResult ? (correct ? 'text-green-600 border-green-600 bg-green-50' : 'text-rose-600 border-rose-600 bg-rose-50') : null

  const numberClass = showResult
    ? correct
      ? 'ring-offset-2 ring-2 ring-green-600 rounded bg-green-600 w-8 shrink-0 text-center text-black select-none'
      : 'ring-offset-2 ring-2 ring-rose-600 rounded bg-rose-600 w-8 shrink-0 text-center text-black select-none'
    : 'ring-offset-2 ring-2 ring-jajko rounded bg-jajko w-8 shrink-0 text-center text-black select-none'

  return (
    <div
      key={id}
      id={id}
      data-type="question"
      data-qtype={question.type}
      data-number={number}
      className={['flex flex-col w-full items-start space-x-2 px-5 justify-start pb-5', showResultWrapperClass].asClass}
    >
      <div className="flex items-start space-x-4 py-4">
        <span className={numberClass}>{number}</span>
        <QuestionRenderer question={question.question} />
      </div>
      <div className="px-10 py-2">
        <input
          disabled={showResult}
          name={id}
          onBlur={(e) => {
            e.currentTarget.value = e.currentTarget.value.trim()
            e.currentTarget.closest('[data-type="question"]')?.setAttribute('data-answer', e.currentTarget.value)
          }}
          onInput={(e) => e.currentTarget.closest('[data-type="question"]')?.setAttribute('data-answer', e.currentTarget.value)}
          type="text"
          autoComplete="new-password"
          className={['border-2 border-jajko px-2 py-1 w-full outline-none rounded w-auto', showResultInputClass].asClass}
        />
        {showResult &&
          (!correct ? (
            <div className="text-rose-500 font-medium text-sm mt-1">
              {question.correctAnswer ? `Správne odpovede: ${question.correctAnswer.join('/')}` : 'Nemá správnu odpoveď'}
              {question.notCaseSensitive && (
                <>
                  <br />
                  <span className="font-normal">Na veľkosti písmen nezáleží</span>
                </>
              )}
            </div>
          ) : (
            <div className="text-slate-900 font-medium text-sm mt-1">
              {question.correctAnswer ? `Všetky správne odpovede: ${question.correctAnswer.join('/')}` : 'Nemá správnu odpoveď'}
              {question.notCaseSensitive && (
                <>
                  <br />
                  <span className="font-normal">Na veľkosti písmen nezáleží</span>
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default FreeResponseQuestion
