import { FC } from 'react'
import Modal from '../Modal'

interface Props {
  open: boolean
}

const SigningInLoaderModal: FC<Props> = ({ open }) => {

  return (
    <Modal open={open} close={() => {}}>
      <div className="grid gap-y-1 text-center">
        <header className="text-lg font-bold text-kura">Prebieha prihlasovanie</header>
        <p className="text-sm text-kura">Čakaj prosím...</p>
      </div>
    </Modal>
  )
}

export default SigningInLoaderModal
